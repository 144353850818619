
const defaultLang = 'en';
const defaultCurrency = 'USD';

export function getUrl(info, alt = 'slug') {
  let lang = defaultLang;
  if (typeof window !== 'undefined') {
    lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : defaultLang;
  } else {
    lang = defaultLang;
  }
  if (info !== null) {
    return info[lang];
  } else {
    return alt;
  }
}

export function translate(info) {
  // console.log(info);
  if (info != undefined) {
    let lang = defaultLang;
    if (typeof window !== 'undefined') {
      lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : defaultLang;
    } else {
      lang = defaultLang;
    }
    if (info !== null) {
      return info[lang];
    } else {
      return '';
    }
  } else {
    return '';
  }
}

export function price_translate(info) {

  let currency = defaultCurrency;
  if (typeof window !== 'undefined') {
    currency = localStorage.getItem("currency") ? localStorage.getItem("currency") : defaultCurrency;
  } else {
    currency = defaultCurrency;
  }
  // console.log(currency);
  // console.log(info);
  if (info !== null) {
    return info[currency];
  } else {
    return '0.00';
  }
}

export function cart_translate(info) {
  let currency = defaultCurrency;
  if (typeof window !== 'undefined') {
    currency = localStorage.getItem("currency") ? localStorage.getItem("currency") : defaultCurrency;
  } else {
    currency = defaultCurrency;
  }
  if (info !== null) {
    return info[currency];
  } else {
    return '0.00';
  }
}

export function get_amount_currency(amount) {
  let currency = defaultCurrency;
  if (typeof window !== 'undefined') {
    currency = localStorage.getItem("currency") ? localStorage.getItem("currency") : defaultCurrency;
  } else {
    currency = defaultCurrency;
  }

  if (amount !== null) {
    if (currency == 'USD') {
      return '$' + amount;
    }
  } else {
    return '0.00';
  }
}


export function get_currency_icon(currency) {
  switch (currency) {
    case "EUR":
      return "€";

    case "AZN":
      return "₼";

    case "USD":
      return "$";

    case "AED":
      return "AED";

    default:
      return "₼";
  }
}
