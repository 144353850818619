import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiUrl, baseUrl } from '../../../config/apiConfig';
import { appName } from '../../../config/appConfig';
import HeaderMenu from './HeaderMenu';
import CustomImage from '../image/CustomImage';
import { faBars, faLocationArrow, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useRouter } from 'next/router';
import axios from 'axios';

const Header = ({ siteSetting }) => {

    // console.log("siteSetting", siteSetting);
    const router = useRouter();

    const [menuActive, setMenuActive] = useState(false);
    const [productList, setProductList] = useState(null);
    const [serviceList, setServiceList] = useState(null);


    useEffect(() => {
        let mounted = true;

        if (mounted) {
            axios
                .get(apiUrl + "/api/headerInfos")
                .then((response) => {
                    // console.log("headerInfos", response.data.data);
                    if (response && response.data.data && response.data.data && response.data.data.productList && response.data.data.productList.length > 0) {
                        let productGroup1 = [];
                        let productGroup2 = [];
                        let productGroup3 = [];
                        for (let index = 0; index < response.data.data.productList.length; index++) {
                            const product = response.data.data.productList[index];
                            if(index < 3) {
                                productGroup1.push(product);
                            }
                            if(index >= 3 && index < 6) {
                                productGroup2.push(product);
                            }
                            if(index >= 6 && index < 9) {
                                productGroup3.push(product);
                            }
                        }
                        setProductList([
                            productGroup1,
                            productGroup2,
                            productGroup3,
                        ]);
                    }
                    if (response && response.data.data && response.data.data && response.data.data.serviceList && response.data.data.serviceList.length > 0) {
                        let serviceGroup1 = [];
                        let serviceGroup2 = [];
                        let serviceGroup3 = [];
                        for (let index = 0; index < response.data.data.serviceList.length; index++) {
                            const service = response.data.data.serviceList[index];
                            if(index < 3) {
                                serviceGroup1.push(service);
                            }
                            if(index >= 3 && index < 6) {
                                serviceGroup2.push(service);
                            }
                            if(index >= 6 && index < 9) {
                                serviceGroup3.push(service);
                            }
                        }
                        setServiceList([
                            serviceGroup1,
                            serviceGroup2,
                            serviceGroup3,
                        ]);
                    }
                })
                .catch((error) => {
                    // console.log(error);
                });
        }
        return () => {
            mounted = false;
        };
    }, [router]);

    const handleMenuButton = () => {
        setMenuActive(true);
    };
    const handleMenuClose = () => {
        setMenuActive(false);
    };


    return (<>
        <header className="header-area position-fixed top-0 start-0 w-100 bg-gradient-blue-light-blue position-relative py-3 py-lg-0">
            <div className="container">
                <div className="header-container">
                    <div className="row align-items-center">
                        <div className="col-3 d-none d-xs-block">
                            <button type="button" className="header-menu-button text-white fs-32 lh-40" onClick={() => handleMenuButton()}>
                                <FontAwesomeIcon icon={faBars} />
                            </button>
                        </div>
                        <div className="col-6 col-lg-3 text-center">
                            <div className="header-logo position-relative d-flex justify-content-center">
                                <Link href="/">
                                    <a className="d-inline-flex w-100 h-100 position-relative">
                                        <CustomImage
                                            src={baseUrl + "/images/logo-white.png"}
                                            alt={appName}
                                            layout="fill"
                                            objectFit="contain"
                                            objectPosition="left"
                                        />
                                    </a>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-6 text-center d-xs-none">
                            <div className="header-menu-area">
                                <HeaderMenu
                                    handleMenuClose={handleMenuClose}
                                    productList={productList}
                                    serviceList={serviceList}
                                />
                            </div>
                        </div>
                        <div className="col-3 text-end">
                            <div className="header-right-area">
                                <a href={`tel:${siteSetting && siteSetting.phone_number ? siteSetting.phone_number : ""}`} className="d-inline-flex py-2 px-3 text-sky-blue text-uppercase radius-12 bg-white fw-semibold fs-20 lh-32">
                                    <span className="text d-xsm-none">LETS TALK</span>
                                    <span className="icon ms-2">
                                        <FontAwesomeIcon icon={faLocationArrow} />
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <button type="button" className={`menu-backdrop d-none d-xs-block position-fixed start-0 top-0 w-100 h-100 border-0 ${menuActive ? 'active' : ''}`} onClick={() => handleMenuClose()}></button>
        <div className={`header-menu-fixed-area d-none d-xs-flex position-fixed flex-column ${menuActive ? 'active' : ''}`}>
            <button type="button" className="header-close-button" onClick={() => handleMenuClose()}>
                <FontAwesomeIcon icon={faTimes} />
            </button>
            <div className="header-logo position-relative">
                <Link href="/">
                    <a>
                        <CustomImage
                            src={baseUrl + "/images/logo.png"}
                            alt={appName}
                            layout="fill"
                            objectFit="contain"
                        />
                    </a>
                </Link>
            </div>
            <div className="header-menu-area flex-grow-1">
                <HeaderMenu
                    handleMenuClose={handleMenuClose}
                    productList={productList}
                    serviceList={serviceList}
                />
            </div>
        </div>
    </>);
};


const mapStateToProps = state => {
    return {
        siteSetting: state.siteSetting.siteSettingInfo
    };
};


export default connect(mapStateToProps, null)(Header);