import React from "react";
import CustomImage from "../image/CustomImage";
import { apiUrl } from "../../../config/apiConfig";

const HeaderMenuItemCard = ({ icon, title, description }) => {
    return (<>
        <span className="icon-image position-relative">
            <CustomImage
                src={icon ? apiUrl + icon : ""}
                layout="fill"
                objectFit="contain"
                objectPosition="center"
            />
        </span>
        <span className="text-info">
            <span className="title d-block fs-19 lh-24 text-dark-blue mb-2">{title}</span>
            <span className="description d-block fs-17 lh-24 fw-medium text-gray-purple">{description}</span>
        </span>
    </>);
};


export default HeaderMenuItemCard;