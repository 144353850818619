export const appName = "Update Tech Ltd.";
export const appWebsite = "https://updatetechltd.com/";
export const devName = "Update Tech Ltd.";
export const devWebsite = "https://updatetechltd.com/";
export const classPrefix = "utech";
export const googleMapApiKey = "AIzaSyDBe0SDmy3dhz8abKvImFqvif8fAVHYts8";
export const googleCaptchaSiteKey = "6LcSqtIdAAAAAJsZp1QcfgSJ8XPQeUkw61gD1_IV";
export const googleMapDefaultLocation = {
    lat: 23.7808875,
    lng: 90.2792371
};
export const googleMapCountryList = ['bd']
export const googleMapDefaultZoom = 14;